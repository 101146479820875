import { Button, Grid, Spacer } from 'dss-ui-library';
import { Form } from 'formik';
import { AvailabilityCheckFormLayoutProps } from '../Form';
import styles from './Small.module.scss';
import { HouseNumber, Street, ZipCode } from '../FormElements';
import { SyntheticEvent, useRef } from 'react';
import { useElementEnteredCheck } from '../../../../../hooks/useElementEnteredCheck';
import { Media, useScrollTo } from '@ncs-frontend-monorepo/utils';

export const AvailabilityCheckFormSmall: React.FC<
  AvailabilityCheckFormLayoutProps
> = ({
  zipCodeProps,
  streetProps,
  houseNumberProps,
  isSubmitting,
  isSubmittable,
}) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const { wasInView } = useElementEnteredCheck({
    ref: targetRef,
    margin: 150,
    maxWidth: 768,
  });
  const scrollToTarget = useScrollTo(targetRef);

  const handleSubmitClick = (e: SyntheticEvent<Element, Event>) => {
    if (!wasInView) {
      e.preventDefault();
      scrollToTarget();
    }
  };

  return (
    <div ref={targetRef}>
      <Form>
        <Grid fullWidth>
          <Spacer b={3} block />
          <Grid.Row>
            <Grid.Column s={3}>
              <ZipCode {...zipCodeProps} />
              <Spacer b={2} block />
            </Grid.Column>
            <Grid.Column s={4} m={4}>
              <Street {...streetProps} />
              <Spacer b={2} block />
            </Grid.Column>
            <Grid.Column s={2} m={2}>
              <HouseNumber {...houseNumberProps} />
              <Spacer b={2} block />
            </Grid.Column>
            <Grid.Column
              className={wasInView ? styles.submitSticky : styles.submitFixed}
              s={3}
              m={3}
            >
              <Button
                disabled={(!isSubmittable || isSubmitting) && wasInView}
                fullWidth
                variant="primary"
                color="red"
                e2e={`availability-check-content`}
                type="submit"
                onClick={handleSubmitClick}
              >
                {isSubmitting ? 'Wird geprüft' : 'Adresse prüfen'}
              </Button>
            </Grid.Column>
            <Media between={['xs', 'm']}>
              <Spacer b={2} block />
            </Media>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};
