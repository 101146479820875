import { useEffect, useState } from 'react';

export function useElementEnteredCheck({
  ref,
  margin = 0,
  maxWidth = 0,
}: {
  ref: React.RefObject<HTMLElement | null>;
  margin?: number;
  maxWidth?: number;
}) {
  const [wasInView, setWasInView] = useState(true);
  useEffect(() => {
    const isElementVisible = () => {
      if (maxWidth && maxWidth < window.innerWidth) return;

      const clientRect = ref.current?.getBoundingClientRect();
      const top = clientRect?.top ?? 0;
      const height = clientRect?.height ?? 0;

      if (top + height < window.innerHeight) {
        setWasInView(true);
      } else {
        setWasInView(top + margin < window.innerHeight);
      }
    };

    isElementVisible();
    window.addEventListener('scroll', isElementVisible);

    return () => {
      window.removeEventListener('scroll', isElementVisible);
    };
  }, [margin, maxWidth]);

  return { wasInView };
}
