import { getEnv } from '@ncs-frontend-monorepo/utils';
import dynamic from 'next/dynamic';
import { useOrder } from '@ncs-frontend-monorepo/order';

const Checkbox = dynamic(
  () => import('dss-ui-library').then(({ Checkbox }) => Checkbox),
  {
    ssr: false,
  },
);

export const CustomerCheckbox: React.FC = () => {
  const { isCustomer, setIsCustomer } = useOrder();

  return (
    <>
      <Checkbox
        label={`Ich habe bereits einen Internet-Anschluss von ${
          getEnv().SITE
        }.`}
        name="isCustomer"
        e2e="is-customer"
        checked={isCustomer}
        onChange={() => setIsCustomer(!isCustomer)}
      />
    </>
  );
};
