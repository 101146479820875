import { Button, Grid, Spacer, Text, useBreakpoints } from 'dss-ui-library';
import { Form } from 'formik';
import { AvailabilityCheckFormLayoutProps } from '../Form';
import styles from '../AvailabilityForm.module.scss';
import { HouseNumber, Street, ZipCode } from '../FormElements';
import { DataLayerEvents } from '../../../../../interfaces/tracking';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { LOGIN_URL } from '../../../../../constants/urls';
import { pushAvailability } from '../../../../../utils/dataLayer/availability';
import { ChevronLink } from '@ncs-frontend-monorepo/content-library';
import { useAvailabilityContext } from '../../../Context';

export const AvailabilityCheckFormModal: React.FC<
  AvailabilityCheckFormLayoutProps
> = ({
  headline,
  subline,
  zipCodeProps,
  streetProps,
  houseNumberProps,
  isSubmitting,
  isSubmittable,
  availabilityType,
}) => {
  const { kundenweltURL } = useAvailabilityContext();
  const loginUrl =
    kundenweltURL ?? `${LOGIN_URL}${isNC() ? '&mandant=nc' : '&mandant=na'}`;
  const { eventLogin } = pushAvailability();
  const { isXS } = useBreakpoints();

  const handleLoginClick = () => {
    eventLogin({
      event: DataLayerEvents.LoginLink,
      checkSource: availabilityType,
      targetPage: loginUrl,
    });
  };
  return (
    <Form>
      <Spacer b={1} block>
        <Text appearance="t2" color="blue">
          {headline}
        </Text>
      </Spacer>
      <Spacer b={3} block>
        <Text appearance="t4">{subline}</Text>
      </Spacer>
      <Grid fullWidth>
        <Grid.Row>
          <Grid.Column s={3}>
            <ZipCode {...zipCodeProps} />
            <Spacer b={4} block />
          </Grid.Column>
          <Grid.Column s={5} m={6}>
            <Street {...streetProps} />
            <Spacer b={4} block />
          </Grid.Column>
          <Grid.Column s={4} m={3}>
            <HouseNumber {...houseNumberProps} />
            <Spacer b={4} block />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {loginUrl && (
            <Grid.Column s={8} m={9} className={styles.colLink}>
              <Text appearance="t5">
                Bist du bereits Kunde? Hier geht&#39;s zu deinem Angebot.
              </Text>
              <Spacer t={1} block />
              <ChevronLink
                appearance="t4_2"
                color="blue"
                e2e="link-meinekundenwelt"
                indent
                url={loginUrl}
                target="_blank"
                onClick={handleLoginClick}
              >
                MeineKundenwelt-Login
              </ChevronLink>
            </Grid.Column>
          )}
          <Grid.Column offsetStartS={9} offsetStartM={10} s={4} m={3}>
            <Button
              disabled={!isSubmittable || isSubmitting}
              fullWidth
              variant="primary"
              color="red"
              e2e="availability-check"
              type="submit"
            >
              {isSubmitting ? 'Wird geprüft' : 'Jetzt prüfen'}
            </Button>
            <Spacer t={isXS ? 3 : 0} block />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};
