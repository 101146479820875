import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { OptionProp } from 'dss-ui-library';
import { FormFieldValues } from './AvailabilityForm';
import { LayoutType } from '../../Availability';
import { AvailabilityTypes, useAvailabilityContext } from '../../Context';
import { HouseNumberProps, StreetProps, ZipCodeProps } from './FormElements';
import { AvailabilityCheckFormEmbedded } from './Layouts/Embedded';
import { AvailabilityCheckFormModal } from './Layouts/Modal';
import { AvailabilityCheckFormSmall } from './Layouts/Small';
import { useAddressAutoSuggest } from '@ncs-frontend-monorepo/forms';

export interface AvailabilityCheckFormProps {
  headline?: string;
  subline?: string;
  layoutType?: LayoutType;
}

const defaultTexts = {
  headline: 'Verfügbarkeitscheck',
  subline: 'Jetzt verfügbare Produkte prüfen.',
};

const defaultTextNetTV = {
  headline: 'NetTV Verfügbarkeitscheck',
  subline:
    'Prüfe jetzt, welches NetTV-Angebot an deiner Adresse verfügbar ist.',
};

export interface AvailabilityCheckFormLayoutProps
  extends AvailabilityCheckFormProps {
  houseNumberProps: HouseNumberProps;
  zipCodeProps: ZipCodeProps;
  streetProps: StreetProps;
  isSubmitting: boolean;
  isSubmittable: boolean;
  availabilityType: AvailabilityTypes;
}

export const AvailabilityCheckForm: React.FC<AvailabilityCheckFormProps> = ({
  headline,
  subline,
  layoutType = LayoutType.Modal,
}) => {
  const { values, isSubmitting, setFieldValue } =
    useFormikContext<FormFieldValues>();
  const [isSubmittable, setSubmittable] = useState(false);
  const { getZipCodes, onZipSelection, getAddresses, onStreetSelection } =
    useAddressAutoSuggest();
  const { availabilityType } = useAvailabilityContext();
  const minCharPostalCode = 3;
  const minCharStreet = 1;

  if (!headline && !subline) {
    headline =
      availabilityType === AvailabilityTypes.TvPlan
        ? defaultTextNetTV.headline
        : defaultTexts.headline;
    subline =
      availabilityType === AvailabilityTypes.TvPlan
        ? defaultTextNetTV.subline
        : defaultTexts.subline;
  }

  useEffect(() => {
    setSubmittable(values.street.length > 0 && values.zipCode.length === 5);
  }, [values]);

  const handleZipSelection = ({
    option,
    targetZipCode,
  }: {
    option: OptionProp;
    targetZipCode: string;
  }) => {
    const { zipCode } = onZipSelection(option);
    // either set autocomplete result or trimmed user input
    setFieldValue(targetZipCode, zipCode ? zipCode : option.value.trim());
  };

  const handleStreetSelection = ({
    option,
    targetStreet,
    targetCity,
  }: {
    option: OptionProp;
    targetStreet: string;
    targetCity: string;
  }) => {
    const { street, city } = onStreetSelection(option);
    if (street && city) {
      setFieldValue(targetStreet, street);
      setFieldValue(targetCity, city);
    }
  };

  const zipCodeProps: ZipCodeProps = {
    background: 'grey',
    minCharPostalCode,
    getZipCodes,
    handleZipSelection,
  };

  const streetProps: StreetProps = {
    background: 'grey',
    getAddresses,
    handleStreetSelection,
    minCharStreet,
    zipCode: values.zipCode,
  };

  const houseNumberProps: HouseNumberProps = {
    background: 'grey',
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(e.target.name, e.target.value.trim());
    },
  };

  const formProps: AvailabilityCheckFormLayoutProps = {
    availabilityType,
    isSubmittable,
    isSubmitting,
    houseNumberProps,
    zipCodeProps,
    streetProps,
    headline,
    layoutType,
    subline,
  };

  const inputWhite: AvailabilityCheckFormLayoutProps = {
    ...formProps,
    houseNumberProps: { ...houseNumberProps, background: 'white' },
    streetProps: { ...streetProps, background: 'white' },
    zipCodeProps: { ...zipCodeProps, background: 'white' },
  };

  if (layoutType === LayoutType.Small) {
    return <AvailabilityCheckFormSmall {...formProps} />;
  }

  if (layoutType === LayoutType.Modal) {
    return <AvailabilityCheckFormModal {...inputWhite} />;
  }

  return <AvailabilityCheckFormEmbedded {...formProps} />;
};
