import { Button, Grid, Spacer, Text, Tooltip } from 'dss-ui-library';
import { Form } from 'formik';
import { AvailabilityCheckFormLayoutProps } from '../Form';
import { LayoutType } from '../../../Availability';
import { pushAvailability } from '../../../../../utils/dataLayer/availability';
import { useAvailabilityContext } from '../../../Context';
import { useEffect } from 'react';
import { DataLayerEvents } from '../../../../../interfaces/tracking';
import styles from '../AvailabilityForm.module.scss';
import { HouseNumber, Street, ZipCode } from '../FormElements';
import { CustomerCheckbox } from './CustomerCheckbox';

const AvailabilityTooltip = () => {
  const { eventTooltip } = pushAvailability();
  const { availabilityType } = useAvailabilityContext();
  useEffect(() => {
    eventTooltip({
      event: DataLayerEvents.Tooltip,
      checkSource: availabilityType,
    });
  }, []);

  return (
    <Text appearance="t5">
      Wir bauen unser Netz in der Region laufend weiter aus. Welche Produkte an
      der angegebenen Adresse verfügbar sind, lässt sich hier prüfen.
    </Text>
  );
};

export const AvailabilityCheckFormEmbedded: React.FC<
  AvailabilityCheckFormLayoutProps
> = ({
  headline,
  subline,
  layoutType,
  zipCodeProps,
  streetProps,
  houseNumberProps,
  isSubmitting,
  isSubmittable,
}) => {
  return (
    <Form>
      <Spacer b={1} block>
        <Text appearance="t2" color="blue" element="h2">
          {headline}
        </Text>
      </Spacer>
      <Spacer b={3} block>
        <Text appearance="t4">{subline}</Text>
        {layoutType === LayoutType.EmbeddedNetSpeed && (
          <Spacer l={2}>
            <Tooltip e2e="availability-check" className={styles.addressInfo}>
              <AvailabilityTooltip />
            </Tooltip>
          </Spacer>
        )}
        {layoutType === LayoutType.EmbeddedTvStandalone && (
          <Spacer t={1} block>
            <CustomerCheckbox />
          </Spacer>
        )}
      </Spacer>
      <Grid fullWidth>
        <Grid.Row>
          <Grid.Column s={3}>
            <ZipCode {...zipCodeProps} />
            <Spacer b={4} block />
          </Grid.Column>
          <Grid.Column s={5} m={6}>
            <Street {...streetProps} />
            <Spacer b={4} block />
          </Grid.Column>
          <Grid.Column s={4} m={3}>
            <HouseNumber {...houseNumberProps} />
            <Spacer b={4} block />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column offsetStartS={9} offsetStartM={10} s={4} m={3}>
            <Button
              disabled={!isSubmittable || isSubmitting}
              fullWidth
              variant="primary"
              color="red"
              e2e={`availability-check-content`}
              type="submit"
            >
              {isSubmitting ? 'Wird geprüft' : 'Jetzt prüfen'}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};
