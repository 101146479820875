import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { AvailabilityCheckForm } from './Form';
import { addressValidation } from '@ncs-frontend-monorepo/forms';
import { LayoutType } from '../../Availability';
import { useAvailability } from '@ncs-frontend-monorepo/availability';

interface FormContainerProps {
  onSubmit(params: FormFieldValues, manualCheck?: boolean): void;
  headline?: string;
  subline?: string;
  layoutType?: LayoutType;
}
export interface FormFieldValues {
  zipCode: string;
  street: string;
  city: string;
  houseNumber: string;
}

export const validationSchema = Yup.object().shape({
  zipCode: addressValidation.zipCode,
  street: addressValidation.street,
  houseNumber: addressValidation.houseNumber,
});

export const initialFormData: FormFieldValues = {
  zipCode: '',
  street: '',
  city: '',
  houseNumber: '',
};

export const AvailabilityForm: React.FC<FormContainerProps> = ({
  onSubmit,
  headline,
  subline,
  layoutType,
}) => {
  const { address } = useAvailability();
  return (
    <Formik
      validateOnBlur
      validateOnChange={false}
      initialValues={address || initialFormData}
      validationSchema={validationSchema}
      onSubmit={(values: FormFieldValues) => {
        // @TODO WEB-8284: hier in neuer forms hook die Adresse updaten
        onSubmit?.(values, true);
      }}
    >
      <AvailabilityCheckForm
        headline={headline}
        subline={subline}
        layoutType={layoutType}
      />
    </Formik>
  );
};
