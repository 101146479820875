import { ChangeEvent } from 'react';
import { FormikFields } from '../../../FormikFields';
import { OptionProp } from 'dss-ui-library';
import {
  AddressSource,
  FetchAddressType,
  GetAddresses,
  GetZipCodes,
} from '@ncs-frontend-monorepo/forms';

export interface ZipCodeProps {
  getZipCodes: ({ zipCode, mode }: GetZipCodes) => Promise<OptionProp[]>;
  handleZipSelection: ({
    option,
    targetZipCode,
  }: {
    option: OptionProp;
    targetZipCode: string;
  }) => void;
  minCharPostalCode: number;
  background: 'white' | 'grey';
}

export const ZipCode: React.FC<ZipCodeProps> = ({
  getZipCodes,
  handleZipSelection,
  minCharPostalCode,
  background,
}) => {
  return (
    <FormikFields.AutoSuggest
      label="PLZ"
      name="zipCode"
      e2e="zipCode"
      placeholder={'z. B. 50126'}
      getOptions={(zipCode: string) =>
        getZipCodes({ zipCode, mode: AddressSource.local })
      }
      onChange={(option: OptionProp) => {
        handleZipSelection({
          option,
          targetZipCode: 'zipCode',
        });
      }}
      minLength={minCharPostalCode}
      background={background}
    />
  );
};

export interface StreetProps {
  getAddresses: ({
    type,
    mode,
    streetPartial,
    zipCode,
  }: GetAddresses) => Promise<OptionProp[]>;
  zipCode: string;
  handleStreetSelection: ({
    option,
    targetStreet,
    targetCity,
  }: {
    option: OptionProp;
    targetStreet: string;
    targetCity: string;
  }) => void;
  background: 'white' | 'grey';
  minCharStreet: number;
}

export const Street: React.FC<StreetProps> = ({
  getAddresses,
  handleStreetSelection,
  zipCode,
  background,
  minCharStreet,
}) => {
  return (
    <FormikFields.AutoSuggest
      label="Straße"
      name="street"
      e2e="street"
      getOptions={(streetPartial: string) =>
        getAddresses({
          type: FetchAddressType.streets,
          mode: AddressSource.local,
          zipCode,
          streetPartial,
        })
      }
      onChange={(option: OptionProp) => {
        handleStreetSelection({
          option,
          targetStreet: 'street',
          targetCity: 'city',
        });
      }}
      minLength={minCharStreet}
      placeholder={'Beispielstraße'}
      background={background}
    />
  );
};

export interface HouseNumberProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  background: 'white' | 'grey';
}

export const HouseNumber: React.FC<HouseNumberProps> = ({
  onChange,
  background,
}) => {
  return (
    <FormikFields.TextField
      placeholder="z. B. 17a-17c"
      label="Hausnummer"
      name="houseNumber"
      e2e="houseNumber"
      onChange={onChange}
      background={background}
    />
  );
};
